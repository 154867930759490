import matchtitle from '../../components/matchtitle';
	import {

		Message
	} from 'element-ui'
	import {
		handlePageQuery,
		handleDeleteSumTable
	} from '@index/api/mymatchs/matchsummarytable';
	export default {
		name: 'matchsummarytable',
		components: {
			matchtitle
		},
		data() {
			return {
				fullscreenLoading:false,
				havAuth: false,
				headers: {
					'Authorization': sessionStorage.getItem('token')
				},
				uploadFileParams: {
					matchId: sessionStorage.getItem("currMatchId")
				},
				path: process.env.VUE_APP_PROXY_URL + "matchsumtable/upload",
				pageSize: 30,
				currPage: 1,
				totalCount: 0,
				con: '',
				queryForm: {},
				tableData: [],
				matchId: sessionStorage.getItem("currMatchId")

			};
		},
		watch: {

		},
		created() {
			this.pageQuery();
		},
		mounted() {

		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			initAuth(havAuth) {
				this.havAuth = havAuth;
				this.havAuth = true;
			},
			handleSizeChange(pageSize) { //改变页面大小
				this.pageSize = pageSize;
				this.pageQuery();
			},
			handleCurrentChange(currPage) { //跳转到某一页
				this.currPage = currPage;
				this.pageQuery();
			},
			fastQuery() {
				this.currPage = 1;
				this.pageQuery();
			},
			pageQuery() {
				handlePageQuery({
					pageSize: this.pageSize,
					currPage: this.currPage,
					con: this.queryForm.con,
					matchId: this.matchId,
					sortWay: [{
						fieldName: 'orderNo',
						sortWay: 'ASC'
					}]
				}).then(res => {
					if (res.status == 200) {
						this.tableData = res.data;
						this.currPage = res.currPage;
						this.pageSize = res.pageSize;
						this.totalCount = res.totalCount;
						this.$nextTick(() => {
							this.$refs.table.bodyWrapper.scrollTop = 0
						});
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}
				})
			},
			uploadSuccess(res) { //上传成功
			this.fullscreenLoading=false;
			this.$refs.upload.clearFiles();
			if (res.status != 200) {
				Message({
					message: '上传失败!',
					type: 'error'
				});
				return;
			}
				Message({
					message: '上传成功!',
					type: 'success',
					duration: 5 * 1000
				});
				this.currPage = 1;
				this.queryForm.con = "";
				this.pageQuery();
				
				
			},
			beforeUpload(){
				this.fullscreenLoading=true;
			},
			uploadError(){
				this.fullscreenLoading=false;
			},
			deleteSumTable() {
				handleDeleteSumTable(this.matchId).then(res => {
					if (res.status == 200) {
						Message({
							type: "success",
							message: res.msg,
							duration: 5 * 1000
						});
						this.pageQuery();
					} else {
						Message({
							type: "error",
							message: res.msg,
							duration: 5 * 1000
						});
					}
				});
			}

		}
	}